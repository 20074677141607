body.ManagersController.edit{
  span.require{
    color:red;
  }

  div.info_alert{
    margin-top:10px;
    &._hide_this{
      display: none;
    }
  }
}
