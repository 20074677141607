body.AboutController.director_edit{
    h1.page-header{
        font-size: 20px;
        margin-bottom: 0;
    }
    img.tune_width{
        width: 100px;
    }
    div.can_link_block{
        display: inline-block;
        vertical-align: top;
        padding-top:7px;
        &.-first, &.-second{
            margin-right: 20px;
        }
        input.can_link{

        }
        label{
            margin:0;
        }
    }

}
