ul.action_list{
    margin:10px 0;
    padding:0;
    > li{
        list-style: none;
        display: inline-block;
        margin:0;
        padding:0;
        > a{
            display: inline-block;
            border-radius: 5px;
            background-color: #0f2efa;
            color: white;
            padding: 0 6px;
            font-size: 14px;
            text-decoration: none;
            &:hover{
                background-color: #0d25c2;
            }
        }
    }
}
