body.ActivityController.activity_edit{
    h1.page-header{
        font-size: 20px;
        margin-bottom: 0;
    }
    input.recap_picture{
        margin-bottom: 5px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    input.activity_guests{
        margin-bottom: 5px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    span.span_icon, span.span_icon_guest{
        color: blue;
        cursor: pointer;
    }
    img.main_picture{
        width:200px;
        margin-top: 5px;
    }
    img.recap_picture{
        width:100px;
        margin-bottom: 5px;
    }
    img.guest_picture{
        width: 100px;
        margin-bottom: 5px;
    }
    div.activity_rundown_block{
        div.activity_rundown_row{
            margin-bottom: 5px;
            position: relative;
            span.span_icon_remove_item{
                display: inline-block;
                position: absolute;
                top:50%;
                left:0;
                color:red;
                transform: translateY(-50%);
                cursor: pointer;
                z-index: 2;
            }
            &:last-child{
                margin-bottom: 0;
            }
            div.-right-col{
                padding-left:0;
            }
        }
    }
    div.activity_guests_block{
        div.activity_guests_row_parent_col{
            div.each_guest_block{
                margin-bottom: 5px;
                position: relative;
                &:last-child{
                    margin-bottom: 0;
                }
                span.span_icon_remove_item_for_guest{
                    display: inline-block;
                    position: absolute;
                    top:8px;
                    left:-15px;
                    color:red;
                    cursor: pointer;
                    z-index: 2;
                }
            }

        }


    }
}
