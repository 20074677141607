body.MainIndexController.partner_index{
    h1.page-header{
        font-size: 20px;
        margin-bottom: 0;
    }
    #sort_alert{
        display: none;
    }
    table.custom_table{
        thead{
            > tr{
                > th{
                    &:first-child{
                        width: 30px;
                    }
                    &:nth-child(4){
                        width:120px;
                    }
                }
            }
        }
        tbody{
            > tr{
                > td{
                    &.empty_td{
                        text-align: center;
                        font-style: italic;
                    }
                    span.icon_move{
                        display: inline-block;
                        cursor: move;
                        color: rgb(72, 186, 250);
                    }
                    img.tune_img{
                        width: 100px;
                    }
                }
            }
        }
    }
}
