@import "./admin_all";
@import "./administrator_login";
@import "./managers_edit";
@import "./index_slider_index";
@import "./index_slider_create";
@import "./index_slider_edit";
@import "./index_partner_index";
@import "./index_partner_create";
@import "./index_partner_edit";
@import "./index_member_index";
@import "./index_member_create";
@import "./index_member_edit";
@import "./index_founder_index";
@import "./index_founder_create";
@import "./index_founder_edit";
@import "./about_news_index";
@import "./about_news_create";
@import "./about_news_edit";
@import "./about_director_index";
@import "./about_director_create";
@import "./about_director_edit";
@import "./activity_index";
@import "./activity_create";
@import "./activity_edit";
